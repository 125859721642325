/* 
PLACEHOLDER DOES NOT COPY THE CODE EVERYTIME YOU ADD MIXIN TO A SELECTOR
reference: https://www.sitepoint.com/8-tips-help-get-best-sass/
*/

%heading {
	text-transform: none;
	font-family: $ffHeading;
	font-weight: $fwBold;
	line-height: 1.2em;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 50px;
}


// STYLES ADDED ABOVE
@mixin typoHeading ($fontSize: 40px, $color: $cHeading) {
	font-size: $fontSize;
	color: $color;
	@extend %heading
}

@mixin typoBodycopy ($color: $cBodycopy, $font-size: 18px) {
	color: $color;
	line-height: 1.5em;
	font-size: $font-size;
	text-align: center;
	font-family: $ffBodycopy;

	strong {
		font-weight: $fwBold;
	}

	@include breakpoint (phone down) {
		font-size: 16px;
	}
}

@mixin typoAnchors () {
	// ...

	&:hover {
		// ...
	}
}