@mixin imgRetina($small, $retina, $width: auto, $height: auto, $path: '#{$pFromCssDist}/images/') {
	background-image: url('#{$path}#{$small}');
	@if $width == cover or $width == contain {
		@if $width != false {
			background-size: $width;
		}
	} @else {
		@if $width != false {
			background-size: $width $height;
		}
	}
	@if $small != $retina {
		@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {
			background-image: url('#{$path}#{$retina}');
		}
	}
}

@mixin bg($img, $width, $height) {
	width: ($width / 2);
	padding-top: ($height / 2);
	background: url('#{$pFromCssDist}/img/#{$img}') no-repeat;
	background-size: ($width / 2) ($height / 2);
	height: 0;
	overflow: hidden;
}

@mixin paddingHeight($height) {
	padding-top: $height;
	overflow: hidden;
	height: 0;
}

@mixin addPreloadSpinner($variant: image, $cBg: $cBlue, $cIcon: $cWhite){
	&:before {
		content: '';
		@if ($variant == image) {
			position: relative;
			padding-top: $drPictures;
		} @else {
			@include position(absolute, 0);
		}
		display: block;
		z-index: 2;
		background: $cBg;
		opacity: 1;
		transition: opacity $tdDefault*4, background $tdDefault*2;
	}

	&:after {
		content: icon-char(spinner);
		@include position(absolute, 50% null null 50%);
		z-index: 3;
		font-family: svgicons;
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin: -20px 0 0 -20px;
		font-size: 40px;
		text-align: center;
		color: $cIcon;
		animation: spinner 3s infinite;
		opacity: 1;
		transition: opacity $tdDefault*4, color $tdDefault*2;
	}

	&.is-loaded {
		&:after,
		&:before {
			opacity: 0;
		}
	}
}

@mixin transitionFix() {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}


@mixin aspectRatio($ratio) {

	&:before {
		display: block;
		content: '';
		padding-bottom: $ratio;
	}

}

@mixin tracking($ps-tracking) {
	letter-spacing: #{ $ps-tracking / 1000 }em;
}

/* ************ MIXINS ************ */

//Adds font-size in rem & px
//Value passed should be the expected px size
//Default value 12 - can be customized to fit project
@mixin font-size($sizeValue: 12 ){
	font-size: $sizeValue + px; //fallback for old browsers
	font-size: (0.0625 * $sizeValue) + rem;

}

//Adds line-height in rem & px
//Value passed should be the expected px size
//Default value 20 - can be customized to fit project
@mixin line-height($heightValue: 20 ){
	line-height: $heightValue + px; //fallback for old browsers
	line-height: (0.0625 * $heightValue) + rem;
}

//Rhythm adds font-size and line-height in rem with a px fallback
//Value passed should be the expected px size
//Default values 12, 20 - can be customized to fit project
@mixin rhythm($sizeValue: 12, $heightValue: 20) {
	@include font-size($sizeValue);
	@include line-height($heightValue);
}

/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin pos($position, $top: null, $right: null, $bottom: null, $left: null) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

/// Shorthand mixin for absolute positioning
/// Serves as an alias for `position(absolute, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin absolute($args...) {
	@include pos(absolute, $args...);
}

/// Shorthand mixin for relative positioning
/// Serves as an alias for `position(relative, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin relative($args...) {
	@include pos(relative, $args...);
}

/// Shorthand mixin for fixed positioning
/// Serves as an alias for `position(fixed, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin fixed($args...) {
	@include pos(fixed, $args...);
}

@mixin rect($width, $height:$width) {
	width: $width;
	height: $height;
}

@mixin absoluteCenter() {
	@include absolute($top:50%, $left:50%);
	transform: translate(-50%, -50%);
}