/**
*
*	Colors
*
*/

$color-a: 				#a683fd;
$color-copy: 			#6841d1;
$cWhite: 				#fff;

/**
*
*	Category Colors
*
*/

$welcome:				#af8eff;
$dashly:				#74d7e8;
$anywhale:				#a77ef9;
$workpocket:			#a4d0e6;
$kuberg:				#579ade;
$about:					#72dbb5;

// borders
$brDefault:			2px;

// fonts
$fwBlack: 			900;
$fwBold:			700;
$fwRegular:			400;
$fwLight:			300;

$ffBodycopy: 		'Hind', Arial, sans-serif;
$ffHeading: 		'Butler', Arial, sans-serif;

// dimensions
$dhSomething:		100px; // $dimensionHeightSomething
$borderTableStroke: 3px;

// transitions
$tdDefault:			.2s;

// paths
$pFromCssDist:		'../..';

//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid

@import 'util/util';

// 1. Global
// ---------

$global-font-size: 100%;
$global-width: rem-calc(1380);
$global-text-direction: ltr;
$global-flexbox: true;
$print-transparent-backgrounds: true;

// Internal variables used for text direction
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 2. Breakpoints
// --------------
// https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// @media (max-width: 1200px) { ... } ==> @include breakpoint(tablet-landscape down) { ... }
$breakpoints: (
	tiny: 0,
	phone: 461px,
	tablet-portrait: 601px,
	tablet-landscape: 901px,
	desktop: 1201px,
	desktop-medium: 1501px,
	desktop-large: 1801px,
	desktop-ales: 2561px
);
$breakpoint-classes: ();

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
	tiny: 15px,
	tablet-landscape: 20px,
);

$grid-column-align-edge: true;
$block-grid-max: 12;



// 4. The Spacers
// --------------
// Define inside spacers map how many spacers you need.
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 3.5)
), $spacers);