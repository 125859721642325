@import "../core/core";

.aside {
  @include position(fixed, 50% -60vh 0 null);
  @include rect(120vh);
  border-radius: 50%;
  background-color: #e0e6ed;
  transform: translate(50%, -50%);
  z-index: 5;

  &__container {
    position: relative;
    width: 50%;
    height: 100%;
  }

  &__content {

    @media screen and (max-width: 1300px) {

      right: -10vw !important;

    }

    @media screen and (max-width: 1024px) {

      right: -20vw !important;

    }

    &--dashly {

      @include position(absolute, 60% 0 null null);
      @include imgRetina('dashly.png', 'dashly@2.png', contain);
      background-repeat: no-repeat;
      background-position-x: right;
      width: 700px;
      opacity: 0;
      transform: translate(50%, -50%);
      @include aspectRatio(90.56%);

    }

    &--wagestream {

      @include position(absolute, 45% 0 null null);
      @include imgRetina('wagestream.png', 'wagestream@2.png', contain);
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: bottom;
      width: 850px;
      opacity: 0;
      transform: translate(50%, -55%);
      @include aspectRatio(116.9%);

    }

    &--mooveez {

      @include position(absolute, 60% 0 null null);
      @include imgRetina('mooveez.png', 'mooveez@2.png', contain);
      background-repeat: no-repeat;
      background-position-x: right;
      width: 650px;
      opacity: 0;
      transform: translate(50%, -50%);
      @include aspectRatio(100%);

    }


    &--sedleckyKaolin {

      @include position(absolute, 60% 0 null null);
      @include imgRetina('sedleckyKaolin.png', 'sedleckyKaolin@2.png', contain);
      background-repeat: no-repeat;
      background-position-x: right;
      width: 650px;
      opacity: 0;
      transform: translate(50%, -50%);
      @include aspectRatio(100%);

    }


    &--anywhale {

      @include position(absolute, 55% 0 null null);
      @include imgRetina('anywhale.png', 'anywhale@2.png', contain);
      background-repeat: no-repeat;
      background-position-x: right;
      width: 520px;
      opacity: 0;
      transform: translate(50%, -50%);
      @include aspectRatio(116.9%);

    }

    &--workpocket {

      @include position(absolute, 55% 0 null null);
      @include imgRetina('workpocket.png', 'workpocket@2.png', contain);
      background-repeat: no-repeat;
      background-position-x: right;
      width: 520px;
      opacity: 0;
      transform: translate(50%, -50%);
      @include aspectRatio(125.76%);

    }

    &--kuberg {

      @include position(absolute, 55% 0 null null);
      background-repeat: no-repeat;
      background-position-x: right;
      width: 650px;
      opacity: 1;
      transform: translate(50%, -50%);
      @include aspectRatio(68.4%);

      .step {
        @include position(absolute, 0);
        width: 100%;
        height: 100%;
        opacity: 0;

        &--1 {
          @include imgRetina('kuberg-step-1.png', 'kuberg-step-1.png', contain);
        }

        &--2 {
          @include imgRetina('kuberg-step-2.png', 'kuberg-step-2.png', contain);
        }

        &--3 {
          @include imgRetina('kuberg-step-3.png', 'kuberg-step-3.png', contain);
        }
      }

    }
  }

  @include breakpoint(tablet-landscape up) {

    @include rect(80vh, 120vh);
    right: -40vh;

  }

  @include breakpoint(desktop-medium up) {

    @include rect(130vh);
    right: -70vh;

    &__content {

      &--dashly {
        width: 850px;
      }

      &--wagestream {
        width: 950px;
      }

      &--mooveez {
        width: 850px;
      }

      &--sedleckyKaolin {
        width: 850px;
      }

      &--anywhale {
        width: 620px;
      }

      &--workpocket {
        width: 620px;
      }

      &--kuberg {
        width: 750px;
      }
    }
  }

  @include breakpoint(desktop-large up) {

    @include rect(150vh);
    right: -80vh;

    &__content {

      &--dashly {
        width: 1050px;
      }

      &--wagestream {
        width: 1150px;
      }

      &--mooveez {
        width: 1000px;
      }

      &--sedleckyKaolin {
        width: 1000px;
      }

      &--anywhale {
        width: 770px;
      }

      &--workpocket {
        width: 750px;
      }

      &--kuberg {
        width: 900px;
      }
    }
  }

}